export const AVAILABLE_HEADER_OPTIONS = {
	experience: {
		name: "Experiência",
		key: "experience",
	},
	projects: {
		name: "Projetos",
		key: "projects",
	},
	resume: {
		name: "Currículo",
		key: "resume",
	},
	contact: {
		name: "Contato",
		key: "contact",   
	}
};